import { Button, Text } from "@centrate-io/barn";
import { CustomTag } from "_styleguide";
import { IconTrash } from "@tabler/icons-react";
import moment from "moment";

export const getColumns = (setManageModal, removeTag, readOnly) => {
  return [
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
      render: (key) => (
        <Text>
          <b>{key}</b>
        </Text>
      ),
    },
    {
      title: "Preview",
      dataIndex: "preview",
      key: "preview",
      render: (preview, tag) => (
        <CustomTag
          backgroundColor={tag.background_color}
          fontColor={tag.font_color}
          tooltip={tag.tooltip}
        >
          {tag.description || "Tag"}
        </CustomTag>
      ),
    },
    {
      title: "Tooltip",
      dataIndex: "tooltip",
      key: "tooltip",
      render: (tooltip) => <Text>{tooltip}</Text>,
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => (
        <Text>{moment(createdAt * 1).format("MMM D, YYYY")}</Text>
      ),
    },
    {
      title: "",
      dataIndex: "manage",
      key: "manage",
      sorter: false,
      width: 100,
      align: "right",
      fixed: "right",
      hidden: readOnly,
      render: (manage, data) => {
        return (
          <div className="table-actions" style={{ justifyContent: "flex-end" }}>
            <Button
              type="secondary"
              size="small"
              className="boxed"
              onClick={() => setManageModal(data.id)}
            >
              Manage Tag
            </Button>
            <Button
              onClick={() => removeTag(data.id)}
              type="nope"
              size="small"
              className="boxed"
            >
              <IconTrash />
            </Button>
          </div>
        );
      },
    },
  ];
};
