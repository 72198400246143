import React, { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { useTags } from "_contexts/useTags";
import { IconPlus } from "@tabler/icons-react";
import { Button, message } from "@centrate-io/barn";
import { CustomTag, Flex, Checkbox } from "_styleguide";
import { applyTags } from "App/Admin/_graphql/mutations/tag";
import { getErrors } from "_helpers/api";
import { Popover } from "antd";
import "./SelectTags.scss";

function SelectTags(props) {
  const client = useApolloClient();
  const { tags } = useTags();
  const [selected, setSelected] = useState(props.tags || []);
  const [saving, setSaving] = useState(false);
  const [open, setOpen] = useState(false);

  const toggleSelected = (tagKey) => {
    if (selected.includes(tagKey)) {
      setSelected(selected.filter((s) => s !== tagKey));
    } else {
      setSelected([...selected, tagKey]);
    }
  };

  // Submit question form
  const saveTags = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: {
          application_id: props.appId,
          tags: selected || [],
        },
        mutation: applyTags,
      });
      message.success(`Tags updated`);
      setOpen(false);
      if (props.reload) props.reload();
    } catch (err) {
      message.error(getErrors(err) || `Error updating tags, try again`);
      setSaving(false);
    }
    setSaving(false);
  };

  const content = (
    <div className="tag-select-popover">
      <div>
        {tags.map((c) => {
          const isChecked = selected?.includes(c.key) ? true : false;
          return (
            <Flex key={c.id} style={{ margin: "6px 0" }}>
              <Checkbox
                checked={isChecked}
                onChange={() => toggleSelected(c.key)}
              >
                <CustomTag
                  backgroundColor={c.background_color}
                  fontColor={c.font_color}
                  tooltip={c.tooltip}
                >
                  {c.description || "---"}
                </CustomTag>
              </Checkbox>
            </Flex>
          );
        })}
      </div>
      <Flex
        gap={8}
        style={{ borderTop: "1px solid #ededed", marginTop: "8px" }}
      >
        <Button
          onClick={() => setOpen(false)}
          block
          size="tiny"
          type="secondary"
          className="boxed"
          style={{ justifyContent: "center", marginTop: "8px", flex: 0.75 }}
        >
          Cancel
        </Button>
        <Button
          onClick={saveTags}
          loading={saving}
          block
          size="tiny"
          type="primary"
          className="boxed"
          style={{ justifyContent: "center", marginTop: "8px", flex: 1 }}
        >
          Save
        </Button>
      </Flex>
    </div>
  );

  return (
    <div className="pp-tag-list">
      {selected?.map((t) => {
        const tagData = tags?.find((c) => t === c.key);
        return tagData ? (
          <CustomTag
            key={tagData.id}
            backgroundColor={tagData.background_color}
            fontColor={tagData.font_color}
            tooltip={tagData.tooltip}
          >
            {tagData.description || "---"}
          </CustomTag>
        ) : null;
      })}
      <Popover content={content} open={open} placement="bottom">
        <Button
          className="tag-btn boxed"
          type="secondary"
          onClick={() => setOpen(true)}
        >
          <IconPlus />
        </Button>
      </Popover>
    </div>
  );
}

export default SelectTags;
