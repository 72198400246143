import { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, message, Modal } from "@centrate-io/barn";
import { createTeam } from "App/Admin/_graphql/mutations/team";
import { getErrors } from "_helpers/api";
import { IconDatabaseImport } from "@tabler/icons-react";
import { Select, InputLabel, Flex } from "_styleguide";
import { Upload as AntDUpload } from "antd";
import { handleFileUpload } from "../../_helpers/upload";
import { normalizeByType } from "../../_helpers/normalize";
import { columnTypes } from "./_helpers/types";
import { downloadCSV } from "_assets/js/helpers";
import "./StandardImport.scss";

const { Dragger } = AntDUpload;

function StandardImport(props) {
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);
  const [company, setCompany] = useState(undefined);
  const [spreadsheet, setSpreadsheet] = useState(undefined);

  const cleanData = (data) => {
    const cleanData = data.map((d) => {
      let newData = {};
      for (const key in d) {
        newData[key] = normalizeByType(d[key], columnTypes[key]);
      }
      return newData;
    });
    setSpreadsheet(cleanData);
  };

  const preview = () => {
    downloadCSV(spreadsheet, "import_preview.csv");
  };

  // Find User (If possible, otherwise assign to company owner)
  // Create Application
  // Create Bank Account (Vault if Debit)
  // Create Payments
  // Add Activity

  // Pause IF (Pause Application & Pause Payments - add reasons)
  // Bank Data is missing
  // Account is set to paused

  const currentCompany = props.companies?.find((c) => c.id === company);

  return (
    <Modal
      wrapClassName="standard-import-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={600}
    >
      <div className="pp-modal-header">
        <div>
          <IconDatabaseImport />
        </div>
        <h3>Standard Import</h3>
      </div>

      {spreadsheet ? (
        <Modal.Body>
          <div className="import-preview">
            <p>
              <b>{spreadsheet?.length}</b> item(s) to import to{" "}
              <b>{currentCompany?.name}</b>
            </p>
            <Button className="boxed" type="secondary" onClick={preview}>
              Preview Data
            </Button>
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <Flex vertical={true}>
            <InputLabel htmlFor="company">Company to Import to *</InputLabel>
            <Select
              value={company}
              onChange={(val) => setCompany(val)}
              size="medium"
              popupClassName="pp-select-dropdown"
              placeholder="Select Company..."
            >
              {props.companies?.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.name} ({c.id})
                </Select.Option>
              ))}
            </Select>
          </Flex>
          <Flex vertical={true}>
            <InputLabel htmlFor="company">CSV Upload *</InputLabel>
            <div className="upload-doc">
              <Dragger
                name="file"
                multiple={false}
                beforeUpload={(file) => handleFileUpload(file, cleanData)}
                accept=".csv"
                disabled={!company}
              >
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single upload. Please upload CSV files only.
                </p>
              </Dragger>
            </div>
          </Flex>
        </Modal.Body>
      )}

      <Modal.Actions>
        <Button
          className="boxed"
          block
          type="secondary"
          onClick={() => setVisible(false)}
        >
          Cancel
        </Button>
        {spreadsheet ? (
          <Button
            block
            className="boxed"
            type="primary"
            disabled={!company || !spreadsheet}
            loading={saving}
          >
            Import Data
          </Button>
        ) : (
          <Button block className="boxed" type="primary" disabled={true}>
            Continue
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
}

export default StandardImport;
