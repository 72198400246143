import { gql } from "@apollo/client";

export const saveManualAccount = gql`
  mutation saveManualAccount(
    $application_id: String!
    $name: String!
    $logo: String
    $url: String
    $account_number: String!
    $routing_number: String!
    $account_type: String!
  ) {
    saveManualAccount(
      application_id: $application_id
      name: $name
      logo: $logo
      url: $url
      account_number: $account_number
      routing_number: $routing_number
      account_type: $account_type
    )
  }
`;

export const saveCreditCard = gql`
  mutation saveCreditCard(
    $application_id: String!
    $issuer: String
    $ccName: String!
    $ccNumber: String!
    $ccExp: String!
    $ccCvc: String!
  ) {
    saveCreditCard(
      application_id: $application_id
      issuer: $issuer
      ccName: $ccName
      ccNumber: $ccNumber
      ccExp: $ccExp
      ccCvc: $ccCvc
    )
  }
`;

export const savePlaidAccount = gql`
  mutation savePlaidAccount($application_id: String!) {
    savePlaidAccount(application_id: $application_id)
  }
`;

export const activateBankAccount = gql`
  mutation activateBankAccount($bank_account_id: String!) {
    activateBankAccount(bank_account_id: $bank_account_id)
  }
`;

export const removeBankAccount = gql`
  mutation removeBankAccount($bank_account_id: String!) {
    removeBankAccount(bank_account_id: $bank_account_id)
  }
`;
