import { message } from "@centrate-io/barn";
// import * as XLSX from "xlsx";
import Papa from "papaparse";

export const handleFileUpload = (file, setSpreadsheet, setWorkbook) => {
  const fileType = file.name.split(".").pop().toLowerCase();

  const reader = new FileReader();

  reader.onload = (e) => {
    const fileData = e.target.result;

    if (fileType === "csv") {
      Papa.parse(fileData, {
        header: true,
        complete: (results) => {
          if (Array.isArray(results?.data) && results?.data?.length > 0) {
            setSpreadsheet(results.data);
          } else {
            message.error("Error parsing CSV file");
          }
        },
        error: () => {
          message.error("Error parsing CSV file");
        },
      });
    } else if (fileType === "xlsx" || fileType === "xls") {
      // const workbook = XLSX.read(fileData, { type: "binary" });
      // setWorkbook({ Filename: file.name, ...workbook });
    } else {
      message.error("Unsupported file format");
    }
  };

  if (fileType === "csv") {
    reader.readAsText(file); // Correctly read the file as text
  } else if (fileType === "xlsx" || fileType === "xls") {
    reader.readAsBinaryString(file); // Correctly read the file as binary string
  }

  return false;
};
