import { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, message, Modal } from "@centrate-io/barn";
import { getErrors } from "_helpers/api";
import { FormInput, Flex } from "_styleguide";
import PayPathLogo from "_assets/logos/icon.svg";
import { formatExpiration, isExpirationValid } from "_assets/js/helpers";
import { saveCreditCard } from "App/Admin/_graphql/mutations/bank_account";
import Cards from "react-credit-cards-2";
import "./CreditCard.scss";

function CreditCard(props) {
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState({
    ccName: "",
    ccNumber: "",
    ccExp: "",
    ccCvc: "",
    focus: "",
  });
  const [issuer, setIssuer] = useState();
  const [isCardValid, setIsCardValid] = useState(false);

  // Submit question form
  const createBankAccount = async () => {
    setSaving(true);

    const formData = {
      application_id: props.form.id,
      issuer: issuer?.issuer || "Credit Card",
      ccName: form.ccName,
      ccNumber: form.ccNumber,
      ccExp: form.ccExp,
      ccCvc: form.ccCvc,
    };

    try {
      await client.mutate({
        variables: formData,
        mutation: saveCreditCard,
      });
      message.success(`Credit card added`);
      await props.sendSocketMessage(
        "bank",
        JSON.stringify({
          ...formData,
          last_four: form?.ccNumber?.slice(-4),
        }),
      );
      if (props.updateBank) props.updateBank();
      if (props.setBankAdded) props.setBankAdded(true);
      setVisible(false);
    } catch (err) {
      message.error(getErrors(err) || "Error adding credit card, try again");
      setSaving(false);
    }
  };

  // Update form by field and value
  const updateForm = (field, value) => {
    const editedform = { ...form };
    editedform[field] = value;
    setForm(editedform);
  };

  const handleInputFocus = (evt) => {
    setForm((prev) => ({ ...prev, focus: evt.target.name }));
  };

  const handleValidation = (type, isValid) => {
    setIsCardValid(isValid);
    setIssuer(type);
  };

  const isExpValid = isExpirationValid(form.ccExp);
  const isCVCValid = form.ccCvc?.length >= 3;
  const isNameValid = form.ccName?.length >= 1;
  const isValid = isExpValid && isCardValid && isCVCValid && isNameValid;

  return (
    <Modal
      wrapClassName="credit-card-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.closeWindow}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={500}
    >
      <Modal.Close onClick={() => setVisible(false)} />

      <div className="connect-icons">
        <div className="customer">
          <img src={PayPathLogo} alt="PayPath" />
        </div>
      </div>

      <div className="connect-title">Add your credit card below</div>

      <Modal.Body>
        <div className="ar-form">
          <div className="ar-title">
            <b>Account:</b>&nbsp;{props.form?.first_name}&nbsp;
            {props.form?.last_name}
          </div>
          <div className="ar-inputs">
            <Cards
              number={form.ccNumber}
              expiry={form.ccExp}
              cvc={form.ccCvc}
              name={form.ccName}
              focused={form.focus}
              callback={handleValidation}
            />
            <Flex vertical={true}>
              <FormInput
                id="cc-name"
                name="cc-name"
                autoComplete="cc-name"
                size="small"
                value={form.ccName}
                onChange={(e) => updateForm("ccName", e.target.value)}
                onFocus={handleInputFocus}
                placeholder="Name on Card *"
                disabled={saving}
              />
            </Flex>
            <Flex vertical={true}>
              <FormInput
                id="cc-number"
                name="cc-number"
                autoComplete="cc-number"
                size="small"
                value={form.ccNumber}
                onChange={(e) => updateForm("ccNumber", e.target.value)}
                onFocus={handleInputFocus}
                placeholder="Card Number *"
                disabled={saving}
                maxLength={issuer?.maxLength || "19"}
              />
            </Flex>
            <Flex vertical={false} gap={12}>
              <FormInput
                className="cc-exp"
                id="cc-exp"
                name="cc-exp"
                autoComplete="cc-exp"
                size="small"
                value={form.ccExp}
                onChange={(e) =>
                  updateForm("ccExp", formatExpiration(e.target.value || ""))
                }
                onFocus={handleInputFocus}
                placeholder="Valid Thru *"
                disabled={saving}
              />
              <FormInput
                className="cc-cvc"
                id="cc-cvc"
                name="cc-cvc"
                autoComplete="cc-cvc"
                size="small"
                value={form.ccCvc}
                onChange={(e) => updateForm("ccCvc", e.target.value)}
                onFocus={handleInputFocus}
                placeholder="CVC *"
                disabled={saving}
                maxLength="4"
              />
            </Flex>
          </div>
        </div>
      </Modal.Body>

      <Modal.Actions>
        <Button
          onClick={createBankAccount}
          type="primary"
          size="large"
          block
          className="green-btn"
          disabled={!isValid}
          loading={saving}
        >
          Save Credit Card
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default CreditCard;
