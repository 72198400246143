import { gql } from "@apollo/client";

export const ownerCompanies = gql`
  query ownerCompanies($showPartners: Boolean) {
    ownerCompanies(showPartners: $showPartners) {
      id
      short_id
      name
      user_id
      companies
      user_count
      role

      parent {
        name
        role
        id
      }

      user {
        id
        short_id
        name
        email
      }

      invited_date
      joined_date
      createdAt
    }
  }
`;

export const companyList = gql`
  query ownerCompanies {
    ownerCompanies(showPartners: false) {
      id
      short_id
      name
      logo
      role
      invited_date
      joined_date
      createdAt
    }
  }
`;
