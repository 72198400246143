import React, { createContext, useContext, useEffect, useState } from "react";
import { getTags } from "App/Admin/_graphql/queries/tag";
const TagsContext = createContext();

// Create a provider component
export const TagsProvider = ({ children, client }) => {
  const [tags, setTags] = useState([]);

  // Load Payments
  const fetchTags = async () => {
    const queryData = await client.query({
      query: getTags,
    });
    setTags(queryData?.data?.getTags || []);
  };

  useEffect(() => {
    fetchTags();
  }, []);

  return (
    <TagsContext.Provider
      value={{
        tags,
        setTags,
        reloadTags: fetchTags,
      }}
    >
      {children}
    </TagsContext.Provider>
  );
};

// Custom hook to use the context
export const useTags = () => {
  return useContext(TagsContext);
};
