import moment from "moment";
import parsePhoneNumber from "libphonenumber-js";
import { validateEmail } from "_assets/js/helpers";

const normalizeDate = (val) => {
  console.log("normalizing date: ", val);
  const date = moment(val, "M/D/YYYY").utc();
  return date.isValid() ? date.toDate() : null;
};

const normalizeExpiration = (val) => {
  const parsedDate = moment(val, "MMM-YY").utc().startOf("day");
  return parsedDate.isValid() ? parsedDate.toDate() : null;
};

const normalizePhone = (val) => {
  const parsed = parsePhoneNumber(val, "US");
  if (parsed && parsed.isValid()) {
    const nationalNumber = parsed.nationalNumber;
    return nationalNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }
  return null;
};

const normalizeMoney = (val) => {
  if (typeof val !== "string") return null;
  const numericString = val.replace(/[^0-9.]/g, "");
  const parsedFloat = parseFloat(numericString);
  return isNaN(parsedFloat) ? null : parseFloat(parsedFloat.toFixed(2));
};

const normalizeInteger = (val) => {
  if (typeof val !== "string") return null;
  const numericString = val.replace(/[^0-9-]/g, "");
  const parsedInteger = parseInt(numericString, 10);
  return isNaN(parsedInteger) ? null : parsedInteger;
};

const normalizeEmail = (val) => {
  if (typeof val !== "string") return null;
  const lowercase = val?.toLowerCase();
  return validateEmail(lowercase) ? lowercase : null;
};

export const normalizeByType = (val, type) => {
  if (type === "Date") {
    return val?.trim() ? normalizeDate(val?.trim()) : null;
  } else if (type === "Email") {
    return val?.trim() ? normalizeEmail(val?.trim()) : null;
  } else if (type === "MonthYear") {
    return val?.trim() ? normalizeExpiration(val?.trim()) : null;
  } else if (type === "Phone") {
    return val?.trim() ? normalizePhone(val?.trim()) : null;
  } else if (type === "Money") {
    return val?.trim() ? normalizeMoney(val?.trim()) : null;
  } else if (type === "Integer") {
    return val?.trim() ? normalizeInteger(val?.trim()) : null;
  } else if (type === "SSN") {
    return val?.trim() ? val?.trim() : null;
  } else {
    return val?.trim() ? val?.trim() : null;
  }
};
