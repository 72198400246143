import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import AddBank from "./AddBank/AddBank";
import CompleteRegistration from "./CompleteRegistration/CompleteRegistration";
import Success from "App/Application/Success/Success";
import PayPathLogo from "_assets/logos/icon.svg";
import { ScrollToTop, Loading } from "_styleguide";
import { getRegistrationForm } from "_helpers/form";
import { message } from "@centrate-io/barn";
import "./Register.scss";

function Register(props) {
  const navigate = useNavigate();
  const [form, setForm] = useState();
  const [audit, setAudit] = useState();
  const [bank, setBank] = useState();
  const [paymentEntryType, setPaymentEntryType] = useState();
  const [done, setDone] = useState(false);

  const updateForm = (f, v) => {
    const n = { ...form };
    n[f] = v;
    setForm(n);
  };

  const getApplicationId = () => {
    const parts = window.location.pathname?.split("/") || [];
    const lastSegment = parts?.pop() || parts?.pop();
    return lastSegment;
  };

  const updateBank = async () => {
    const applicationShortId = getApplicationId();
    let formData = await getRegistrationForm(props.client, applicationShortId);
    if (formData?.currentForm) {
      setBank(formData?.bank);
    }
  };

  const pageProps = {
    form,
    audit,
    paymentEntryType,
    bank,
    setForm,
    setDone,
    updateBank,
    u: updateForm,
    client: props.client,
  };

  /* Load initial form data */
  useEffect(() => {
    const getInitialForm = async () => {
      const pathSplit = window.location.pathname?.split("/");
      const applicationShortId = getApplicationId();
      let formData = await getRegistrationForm(
        props.client,
        applicationShortId,
      );
      if (formData?.currentForm) {
        setForm(formData?.currentForm);
        setAudit(formData?.audit);
        setBank(formData?.bank);
        setPaymentEntryType(formData.paymentEntryType || "plaid");
      } else {
        message.error("Application not found, try again");
      }
      if (formData?.status === "subscription" && pathSplit?.[1] !== "b") {
        navigate(`/s/${applicationShortId}`);
      }
    };
    getInitialForm();
  }, [done]); // eslint-disable-line react-hooks/exhaustive-deps

  return !form ? (
    <Loading />
  ) : (
    <React.Fragment>
      <div className="main-logo-container ba-version">
        <img className="main-logo" src={PayPathLogo} alt="PayPath" />
      </div>
      <div id="app-scroll" className={`app-box`}>
        <ScrollToTop />
        <Routes>
          <Route
            exact
            path="/r/:application_id"
            id="complete-registration"
            element={
              <CompleteRegistration
                {...props}
                {...pageProps}
                shortId={getApplicationId()}
              />
            }
          />
          <Route
            exact
            path="/b/:application_id"
            id="add-bank"
            element={
              <AddBank {...props} {...pageProps} shortId={getApplicationId()} />
            }
          />
          <Route
            exact
            path="/s/:application_id"
            id="success"
            element={<Success {...props} {...pageProps} />}
          />
        </Routes>
      </div>
    </React.Fragment>
  );
}

export default Register;
