import { Text } from "@centrate-io/barn";
import { Link } from "react-router-dom";
import {
  IconSignature,
  IconWriting,
  IconAddressBook,
  IconCalendar,
  IconMap2,
  IconFile,
  IconProgressAlert,
  IconUser,
  IconTag,
} from "@tabler/icons-react";
import { prettyPhone } from "_assets/js/helpers";
import { Button } from "@centrate-io/barn";
import { appMap } from "_helpers/application";
import { Tag, SelectTags } from "_styleguide";
import moment from "moment";

export const getColumns = (category, setIsModifying) => {
  return [
    {
      title: (
        <div className="column-name">
          <IconSignature />
          Customer
        </div>
      ),
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 280,
      render: (name, data) => (
        <Link to={`/customer/${data.short_id}`} className="application-title">
          <Text className="doc-name">
            <IconFile />
            <b>
              {data.first_name} {data.last_name}
            </b>
          </Text>
          <Text className="helper" style={{ paddingLeft: "20px" }}>
            <i>ID: {data.short_id}</i>
          </Text>
        </Link>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconProgressAlert />
          Status
        </div>
      ),
      dataIndex: "status",
      key: "status",
      sorter: true,
      width: 130,
      align: "center",
      render: (status) => (
        <Tag size="small" type={appMap[status]?.type}>
          {appMap[status]?.title}
        </Tag>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconTag />
          Tags
        </div>
      ),
      dataIndex: "tags",
      key: "tags",
      render: (tags, data) => <SelectTags appId={data.id} tags={tags} />,
    },
    {
      title: (
        <div className="column-name">
          <IconWriting />
          Reason
        </div>
      ),
      dataIndex: "reason",
      key: "reason",
      sorter: false,
      align: "left",
      hidden: category === "active",
      render: (reason) => <Text>{reason}</Text>,
    },
    {
      title: (
        <div className="column-name">
          <IconAddressBook />
          Contact
        </div>
      ),
      dataIndex: "phone",
      key: "phone",
      width: 220,
      sorter: true,
      render: (phone, data) => (
        <div>
          <Text>{data?.email}</Text>
          <Text>{prettyPhone(phone)}</Text>
        </div>
      ),
    },

    // {
    //   title: (
    //     <div className="column-name">
    //       <IconIdBadge2 />
    //       SSN
    //     </div>
    //   ),
    //   dataIndex: "ssn",
    //   key: "ssn",
    //   width: 120,
    //   sorter: true,
    //   render: (ssn) => <Text>{ssn}</Text>,
    // },
    // {
    //   title: (
    //     <div className="column-name">
    //       <IconCalendarUser />
    //       DOB
    //     </div>
    //   ),
    //   dataIndex: "dob",
    //   key: "dob",
    //   width: 120,
    //   sorter: true,
    //   render: (dob) =>
    //     dob ? (
    //       <Text>{moment(parseInt(dob)).format("M/D/YYYY")}</Text>
    //     ) : (
    //       <i>n/a</i>
    //     ),
    // },
    {
      title: (
        <div className="column-name">
          <IconMap2 />
          Address
        </div>
      ),
      dataIndex: "address",
      key: "address",
      width: 300,
      sorter: true,
      hidden: category !== "active",
      render: (address, data) =>
        data.address ? (
          <div>
            <Text>
              {data.address} {data.address_line_two}
            </Text>
            <Text>
              {data.city}, {data.state} {data.zip}
            </Text>
          </div>
        ) : null,
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Subscribed
        </div>
      ),
      dataIndex: "audit.subscribed_date",
      key: "audit.subscribed_date",
      width: 150,
      sorter: true,
      render: (audit, data) =>
        data?.audit?.subscribed_date ? (
          <Text>
            {moment(parseInt(data?.audit?.subscribed_date)).format(
              "M/D/YY @ h:mm a",
            )}
          </Text>
        ) : (
          <i>n/a</i>
        ),
    },
    {
      title: (
        <div className="column-name">
          <IconUser />
          Signed By
        </div>
      ),
      dataIndex: "user",
      key: "user",
      width: 180,
      render: (name, data) => (
        <div>
          <Text className="doc-name">
            <IconUser />
            <b>{data?.user?.name}</b>
          </Text>
          <Text className="helper" style={{ paddingLeft: "20px" }}>
            <i>{data?.company?.name}</i>
          </Text>
        </div>
      ),
    },
    {
      title: <div className="column-name">&nbsp;</div>,
      dataIndex: "manage",
      key: "manage",
      width: 120,
      align: "center",
      fixed: "right",
      render: (address, data) => (
        <div className="table-actions">
          <Link
            to={`/customer/${data.short_id}`}
            onClick={() => setIsModifying(false)}
          >
            <Button type="primary" size="small">
              Manage Customer
            </Button>
          </Link>
        </div>
      ),
    },
  ];
};
