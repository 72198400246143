import { gql } from "@apollo/client";

export const saveTag = gql`
  mutation saveTag(
    $tag_id: String
    $key: String!
    $background_color: String!
    $font_color: String!
    $tooltip: String
    $description: String!
  ) {
    saveTag(
      tag_id: $tag_id
      key: $key
      background_color: $background_color
      font_color: $font_color
      tooltip: $tooltip
      description: $description
    )
  }
`;

export const applyTags = gql`
  mutation applyTags($application_id: String!, $tags: [String]) {
    applyTags(application_id: $application_id, tags: $tags)
  }
`;

export const removeTag = gql`
  mutation removeTag($tag_id: String!) {
    removeTag(tag_id: $tag_id)
  }
`;
