import React, { Component } from "react";
import { Tooltip } from "@centrate-io/barn";
import "./CustomTag.scss";

class CustomTag extends Component {
  render() {
    const props = { ...this.props };
    props.className = "pp-custom-tag " + (props.className || "");

    if (props.size) {
      props.className += " pp-custom-tag-" + props.size;
    }

    props.style = {
      background: props.backgroundColor,
      color: props.fontColor,
    };

    if (props.tooltip) {
      props.style.cursor = "pointer";
      return (
        <Tooltip title={props.tooltip}>
          <div {...props} />
        </Tooltip>
      );
    } else {
      return <div {...props} />;
    }
  }
}

export default CustomTag;
