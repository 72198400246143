import { gql } from "@apollo/client";

export const getTags = gql`
  query {
    getTags {
      id
      key
      background_color
      font_color
      tooltip
      description
      createdAt
    }
  }
`;

export const getTag = gql`
  query getTag($tag_id: ID) {
    getTag(tag_id: $tag_id) {
      key
      background_color
      font_color
      tooltip
      description
    }
  }
`;
