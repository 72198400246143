import React from "react";
import { Button, Tooltip } from "@centrate-io/barn";
import {
  IconDatabaseImport,
  IconPlayerPlay,
  IconInfoCircle,
} from "@tabler/icons-react";
import { Tag } from "_styleguide";
import "./ImportCard.scss";

function ImportCard({ info, from, importAction }) {
  return (
    <div className={`imp-card`}>
      <div className="int-info">
        <div className="int-brand">
          <IconDatabaseImport />
          <h3>{info.title}</h3>
        </div>
        <p>{info.description}</p>
        <div className="int-tags">
          {from?.includes("forth") ? (
            <Tooltip title="This import is commonly used from moving data from Forth CRM > PayPath">
              <Tag>Forth (CRM)</Tag>
            </Tooltip>
          ) : null}
        </div>
      </div>
      <div className="int-actions">
        <Tooltip title="Required items: Company, CSV">
          <Button className="boxed" type="secondary">
            <IconInfoCircle />
          </Button>
        </Tooltip>
        <Button className="boxed" type="primary" onClick={importAction}>
          <IconPlayerPlay /> Start Import
        </Button>
      </div>
    </div>
  );
}

export default ImportCard;
