import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, message, Modal } from "@centrate-io/barn";
import { getTag } from "App/Admin/_graphql/queries/tag";
import { saveTag } from "App/Admin/_graphql/mutations/tag";
import { getErrors } from "_helpers/api";
import { IconTag } from "@tabler/icons-react";
import { FormInput, InputLabel, Flex, Loading, CustomTag } from "_styleguide";
import { ColorPicker } from "antd";
import "./ManageTag.scss";

function ManageTag(props) {
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [tag, setTag] = useState();

  const isEditing = props.tagId !== "new";

  const fetchTag = async () => {
    if (isEditing) {
      const queryData = await client.query({
        variables: { tag_id: props?.tagId },
        query: getTag,
      });
      setLoading(false);
      setTag(queryData?.data?.getTag);
    } else {
      setLoading(false);
      setTag({
        key: undefined,
        background_color: "#FFFFFF",
        font_color: "#000000",
        tooltip: undefined,
        description: undefined,
      });
    }
  };

  // Submit question form
  const saveForm = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: {
          tag_id: props.tagId !== "new" ? props.tagId : null,
          ...tag,
        },
        mutation: saveTag,
      });
      message.success(`Tag successfully ${isEditing ? "saved" : "created"}`);
      if (props.reload) props.reload();
      setVisible(false);
    } catch (err) {
      message.error(
        getErrors(err) ||
          `Error ${isEditing ? "saving" : "creating"} team, try again`,
      );
      setSaving(false);
    }
    setSaving(false);
  };

  const updateForm = (field, value) => {
    const editedform = { ...tag };
    editedform[field] = value;
    setTag(editedform);
  };

  useEffect(() => {
    fetchTag();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isValid =
    tag?.key?.length > 0 &&
    tag?.description?.length > 0 &&
    tag?.background_color?.length >= 7 &&
    tag?.font_color?.length >= 7;

  return (
    <Modal
      wrapClassName="manage-tag-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={loading ? true : false}
      width={550}
    >
      {loading ? (
        <Modal.Body>
          <Loading />
        </Modal.Body>
      ) : (
        <>
          <div className="pp-modal-header">
            <div>
              <IconTag />
            </div>
            <h3>{isEditing ? "Manage" : "Create"} Tag</h3>
          </div>

          <Modal.Body>
            <div className="mtg-preview">
              <CustomTag
                backgroundColor={tag.background_color}
                fontColor={tag.font_color}
                tooltip={tag.tooltip}
              >
                {tag.description || "Tag"}
              </CustomTag>
              <span>PREVIEW</span>
            </div>
            <Flex vertical={true} gap={16}>
              <Flex vertical={true}>
                <InputLabel htmlFor="key">Tag Key *</InputLabel>
                <FormInput
                  id="key"
                  value={tag.key}
                  disabled={saving}
                  onChange={(e) =>
                    updateForm(
                      "key",
                      e.target.value?.replace(/[^a-zA-Z_]/g, "")?.toUpperCase(),
                    )
                  }
                  placeholder="e.g. IN_PROGRESS"
                  size="medium"
                />
              </Flex>
              <Flex vertical={true}>
                <InputLabel htmlFor="description">Label *</InputLabel>
                <FormInput
                  id="description"
                  value={tag.description}
                  disabled={saving}
                  onChange={(e) => updateForm("description", e.target.value)}
                  placeholder="e.g. In Progress"
                  size="medium"
                />
              </Flex>
              <Flex vertical={false} gap={16}>
                <Flex vertical={true} style={{ flex: 1 }}>
                  <InputLabel htmlFor="background_color">
                    Background Color *
                  </InputLabel>
                  <div>
                    <ColorPicker
                      value={tag.background_color}
                      onChange={(val, css) =>
                        updateForm("background_color", css)
                      }
                      defaultFormat="hex"
                      format="hex"
                      showText
                      allowClear
                      disabledAlpha
                    />
                  </div>
                </Flex>
                <Flex vertical={true} style={{ flex: 1 }}>
                  <InputLabel htmlFor="font_color">Text Color *</InputLabel>
                  <div>
                    <ColorPicker
                      value={tag.font_color}
                      onChange={(val, css) => updateForm("font_color", css)}
                      defaultFormat="hex"
                      format="hex"
                      showText
                      allowClear
                      disabledAlpha
                    />
                  </div>
                </Flex>
              </Flex>
              <Flex vertical={true}>
                <InputLabel htmlFor="description">Tooltip</InputLabel>
                <FormInput
                  id="tooltip"
                  value={tag.tooltip}
                  disabled={saving}
                  onChange={(e) => updateForm("tooltip", e.target.value)}
                  placeholder="e.g. This lead is currently in progress"
                  size="medium"
                />
              </Flex>
            </Flex>
          </Modal.Body>

          <Modal.Actions>
            <Button
              disabled={saving}
              block
              type="secondary"
              onClick={() => setVisible(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={saveForm}
              loading={saving}
              block
              type="primary"
              disabled={!isValid}
            >
              {isEditing ? "Save" : "Create"} Tag
            </Button>
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
}

export default ManageTag;
