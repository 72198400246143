import React, { useState, useEffect } from "react";
import { IconX } from "@tabler/icons-react";
import { useApolloClient } from "@apollo/client";
import { useBar } from "_contexts/useBar";
import { useIntegrations } from "_contexts/useIntegrations";
import Search from "../Search/Search";
import Missing from "../Missing/Missing";
import { getCompany } from "_graphql/queries/company";
import "./Mail.scss";

function Mail(props) {
  const client = useApolloClient();
  const { integrations } = useIntegrations();
  const { closeCall, activeData } = useBar();
  const [companyData, setCompanyData] = useState(false);

  // Load Settings
  const fetchCompany = async () => {
    const companyResponse = await client.query({
      query: getCompany,
    });
    setCompanyData(companyResponse?.data?.getCompany);
  };

  useEffect(() => {
    fetchCompany();
  }, []);

  if (!integrations?.mail) return <Missing type="mail" />;

  return !activeData?.customer ? (
    <Search type="mail" />
  ) : (
    <div className="bar-mail">
      <h4>Mail goes here</h4>
      <p>{companyData || ""}</p>
      <div className="dialer-close" onClick={closeCall}>
        <IconX />
      </div>
    </div>
  );
}

export default Mail;
